<template>
  <v-app>
    <Navbar v-if="this.$route.path != '/login'"></Navbar>
    <!-- if is isLoggedInAsUser and !customer, show component which loads them -->
    <CustomerSelection v-if="this.$route.path != '/login' && !this.tokenNotGivenOrExpired()" />
    <!-- if customer selected but no deliveryAdress and more than one is defined-->
    <DeliveryAdressSelection v-if="this.$route.path != '/login' && this.$route.path != '/freestock'&& this.customer && !this.deliveryAdress" />
    <!--error-->
    <ErrorNotifier />
    <!--loading-->
    <!--<Loader :showLoading="showLoading"/>-->
    <Loader />

    <v-content class="mb-4 grey lighten-4">
      <router-view></router-view>
    </v-content>

    <sfooter></sfooter>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import sfooter from "@/components/Footer.vue";
import ErrorNotifier from "@/components/ErrorNotifier.vue";
import Loader from "@/components/Loader.vue";
import CustomerSelection from "@/components/CustomerSelection.vue";
import DeliveryAdressSelection from "@/components/DeliveryAdressSelection.vue";
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

export default {
  name: "App",

  components: {
    Navbar,
    sfooter,
    ErrorNotifier,
    Loader,
    CustomerSelection,
    DeliveryAdressSelection
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['tokenNotGivenOrExpired']),
    ...mapState(['customer', 'deliveryAdress'])
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "SCHUBERTH ORDER";
      },
    },
  },
};
</script>

<style>
.captionSmall {
  font-size: 0.625rem !important;
  letter-spacing: 0.1666666667em !important;
}
</style>